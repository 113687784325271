<template>

  <div>
    <div style="height: 0; opacity: 0; overflow: hidden; min-width:790px; width:790px; max-width:unset;">
      <b-card
        id="pdfGenerateDiv"
        ref="pdfGenerateDivRef"
        no-body
        class="invoice-preview-card"
      >
        <div
          v-for="pg in pdfTotalPage"
          :key="pg"
        >
          <div
            class="card__inner"
            style="height: 1080px;"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row invoice-spacing mt-0">
                <div>
                  <div class="logo-wrapper">
                    <img
                      style="height:60px;object-fit:contain;"
                      :src="purchaseRequest.company ? (purchaseRequest.company.logo ? purchaseRequest.company.logo : '/nirvana-memorial-garden-logo.png') : '/nirvana-memorial-garden-logo.png'"
                      alt="Logo"
                      crossorigin="anonymous"
                    >
                  </div>
                </div>
                <div
                  style="min-width: fit-content; width: fit-content;"
                  class="px-2"
                >
                  <h4 style="text-align: center; text-transform: uppercase; color: #000000; font-weight: 900; font-size: 12px; line-height: 18px;">
                    Purchase Order
                  </h4>
                </div>
                <div class="mt-md-0 mt-2 text-right">
                  <h4
                    class="invoice-title"
                    style="font-size: 18px;line-height: 21px;color: #104D9D; margin:0;"
                  >
                    {{ purchaseRequest.caseID }}
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p
                      class="invoice-date"
                      style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                    >
                      <span class="semi-bold">PR / CAPEX No.:</span> {{ purchaseRequest.stringID }}
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p
                      class="invoice-date"
                      style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                    >
                      <span class="semi-bold">Created On:</span> {{ dateFormatWithTime(pOrder.createdAt) }}
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr
              class="invoice-spacing"
              style="margin-top:25px!important"
            >

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0 main__body po__create preview_order_pdf"
            >
              <b-row v-if="pg == 1">
                <b-col cols="md-6">
                  <b-table-simple
                    borderless
                    responsive
                  >
                    <b-tbody>
                      <b-tr>
                        <b-th width="230px">
                          Order From
                        </b-th>
                        <b-td>
                          <span class="text-bold-black">{{ vendorName }}</span>
                          <br>
                          <span class="text-pre">{{ vendorAddress }}</span>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Vendor Tel
                        </b-th>
                        <b-td>{{ companyContact ? companyContact : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Vendor Fax
                        </b-th>
                        <b-td>{{ companyFax ? companyFax : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Vendor H/P
                        </b-th>
                        <b-td>{{ companyHP ? companyHP : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Email
                        </b-th>
                        <b-td>{{ companyEmail ? companyEmail : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Vendor Contact Person
                        </b-th>
                        <b-td>{{ salesPersonName ? salesPersonName : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Payment Terms
                        </b-th>
                        <b-td>{{ paymentTerms ? paymentTerms : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Delivery Lead Time
                        </b-th>
                        <b-td>{{ deliveryLeadTime ? deliveryLeadTime : '-' }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
                <b-col cols="md-6">
                  <b-table-simple
                    borderless
                    responsive
                  >
                    <b-tbody>
                      <b-tr>
                        <b-th width="230px">
                          Deliver To
                        </b-th>
                        <b-td>
                          <span class="text-bold-black">{{ resolveDeliverToName() }}</span>
                          <br>
                          <span class="text-pre">{{ address }}</span>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Delivery Info
                        </b-th>
                        <b-td><span class="text-pre">{{ deliveryInfo ? deliveryInfo : '-' }}</span></b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Contact Person
                        </b-th>
                        <b-td>{{ mainContactPerson ? mainContactPerson : '-' }}{{ secondContactPerson ? `, ${secondContactPerson}` : '' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Tel
                        </b-th>
                        <b-td>{{ mainContactPersonContact ? mainContactPersonContact : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Mobile
                        </b-th>
                        <b-td>{{ backupContact ? backupContact : '-' }} ({{ secondContactPerson ? secondContactPerson : '-' }})</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Fax
                        </b-th>
                        <b-td>{{ contactFax ? contactFax : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Email
                        </b-th>
                        <b-td>{{ contactEmail ? contactEmail : '-' }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
              <b-row v-if="chunksOfItemRequests[pg - 1] && chunksOfItemRequests[pg - 1].length">
                <b-col
                  cols="12"
                  class="p-0 item__description_table"
                >
                  <!-- Invoice Description: Table -->
                  <b-table-simple
                    ref="teamsTable"
                    class="position-relative has_padding"
                    responsive
                    caption-top
                  >
                    <b-thead>
                      <b-tr class="group__tag">
                        <b-th>
                          No
                        </b-th>
                        <b-th>
                          Description
                        </b-th>
                        <b-th>
                          Unit
                        </b-th>
                        <b-th>
                          Qty Ordered
                        </b-th>
                        <b-th>
                          Unit Cost (S$)
                        </b-th>
                        <b-th>
                          Amount (S$)
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody
                      class="white-body-background"
                    >
                      <b-tr
                        v-for="(item, index) in chunksOfItemRequests[pg - 1]"
                        :key="index"
                      >
                        <b-td>
                          {{ resolveItemCounter(pg, index) }}
                        </b-td>
                        <b-td style="max-width: 50rem;">
                          <span class="text-bold semi-bold">{{ item.itemName }}</span>
                          <br>
                          <span class="text-pre">{{ item.itemDescription }}</span>
                        </b-td>
                        <td>
                          {{ item.unit }}
                        </td>
                        <b-td>
                          {{ item.quantity }}
                        </b-td>
                        <td>
                          {{ item.unitCost }}
                        </td>
                        <td>
                          {{ numberFormat(item.amount) }}
                        </td>
                      </b-tr>
                      <tr v-if="(pg == (pdfTotalPage - 1) && !chunksOfItemRequests[pg - 1]) || (pg == pdfTotalPage && chunksOfItemRequests[pg - 1])">
                        <td />
                        <td colspan="4">
                          Subtotal
                        </td>
                        <td>
                          {{ pOrder.subTotal ? numberFormat(pOrder.subTotal) : 0.00 }}
                        </td>
                      </tr>
                      <tr v-if="(pg == (pdfTotalPage - 1) && !chunksOfItemRequests[pg - 1]) || (pg == pdfTotalPage && chunksOfItemRequests[pg - 1])">
                        <td />
                        <td colspan="4">
                          GST {{ pOrder.gstPercentage }}%
                        </td>
                        <td>
                          {{ pOrder.gst == '-' ? '-' : (pOrder.gst ? numberFormat(pOrder.gst) : 0.00) }}
                        </td>
                      </tr>
                      <tr v-if="(pg == (pdfTotalPage - 1) && !chunksOfItemRequests[pg - 1]) || (pg == pdfTotalPage && chunksOfItemRequests[pg - 1])">
                        <td />
                        <td colspan="4">
                          Grand Total
                        </td>
                        <td>
                          {{ pOrder.grandTotal ? numberFormat(pOrder.grandTotal) : 0.00 }}
                        </td>
                      </tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card-body>

            <div
              v-if="pg == pdfTotalPage"
              style="position: relative; bottom: 0; width: 100%;"
            >
              <div :style="{ height: gapHeight+'px' }" />
              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0 footer__area">
                <b-row>

                  <!-- Col: Sales Persion -->
                  <b-col
                    v-for="(signatory, index) of resolveSignatoryAssigns"
                    :key="index"
                    cols="3"
                    md="3"
                    class="mt-md-1 mt-1"
                    order="2"
                    order-md="1"
                  >
                    <b-card-text class="mb-0">
                      <span class="font-weight-bold">{{ loas[index].code }}</span>
                      <div class="sign-box">
                        <p>Pending Signature</p>
                      </div>
                      <p class="ml-75 semi-bold">
                        {{ resolveSignatoryName(signatory) }}
                      </p>
                      <p class="ml-75">
                        {{ resolveSignatoryDepartment(signatory) }}
                      </p>
                    </b-card-text>
                  </b-col>
                  <b-col
                    cols="3"
                    md="3"
                    class="mt-md-1 mt-1"
                    order="2"
                    order-md="1"
                  >
                    <b-card-text class="mb-0">
                      <span class="font-weight-bold">Approved by</span>
                      <div class="sign-box">
                        <p>Pending Signature</p>
                      </div>
                      <p class="ml-75 semi-bold">
                        {{ resolveSignatoryName(signatoryAssigns[signatoryOption - 1]) }}
                      </p>
                      <p class="ml-75">
                        {{ resolveSignatoryDepartment(signatoryAssigns[signatoryOption - 1]) }}
                      </p>
                    </b-card-text>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-card-body class="invoice-padding pb-0 terms__block">
                <b-row
                  class="content-header"
                >

                  <!-- Content Left -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <h4>Terms and Conditions</h4>
                    <b-card-text class="mb-0">
                      <p>1. Acknowledgement copy of this order form must be stamped, signed and returned to us for confirmation of acceptance forthwith.</p>
                      <p>2. INVOICE IN DUPLICATE indicating Delivery Note number and date of delivery.</p>
                      <p>3. Credit terms begin at date of receipt of invoice or good whichever is later.</p>
                      <p>4. A higher price than shown on the order must not be charged without our authority.</p>
                      <p>5. Please execute out for goods listed above forthwith. This right is reserved to cancel this order if goods are not supplied within that stipulated above. Goods supplied not in accordance with our specifications will be returned at your own risk and expense.</p>
                      <p>6. We do not hole ourselves responsible for any goods delivered unless a written order duly signed by the authorised official our company can be produced. Suppliers should therefore in their own interest see that such order is obtained before executing order.</p>
                      <p>7. Please email or call us when deliver. Kindly note that our delivery time is from 8:30am - 11:00am or 1pm - 4pm, Monday - Friday excluding Public Holiday.</p>
                    </b-card-text>
                  </b-col>
                </b-row>
              </b-card-body>
            </div>
          </div>
          <!-- <div
            v-if="pg != pdfTotalPage"
            class="html2pdf__page-break"
          /> -->
        </div>
      </b-card>
      <b-card
        no-body
        class="invoice-preview-card"
      >
        <div
          class="card__inner"
          style="height: 1120px;"
        >
          <!-- Header -->
          <b-card-body
            id="main-header"
            class="invoice-padding pb-0"
          >
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div class="logo-wrapper">
                <img
                  style="height:60px;object-fit:contain;"
                  :src="purchaseRequest.company ? (purchaseRequest.company.logo ? purchaseRequest.company.logo : '/nirvana-memorial-garden-logo.png') : '/nirvana-memorial-garden-logo.png'"
                  alt="Logo"
                  crossorigin="anonymous"
                >
              </div>
              <div
                style="min-width: fit-content; width: fit-content;"
                class="px-2"
              >
                <h4 style="text-align: center; text-transform: uppercase; color: #000000; font-weight: 900; font-size: 12px; line-height: 18px;">
                  Purchase Order
                </h4>
              </div>
              <div class="mt-md-0 mt-2 text-right">
                <h4
                  class="invoice-title"
                  style="font-size: 18px;line-height: 21px;color: #104D9D; margin:0;"
                >
                  {{ purchaseRequest.caseID }}
                </h4>
                <div class="invoice-date-wrapper">
                  <p
                    class="invoice-date"
                    style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                  >
                    <span class="semi-bold">PR / CAPEX No.:</span> {{ purchaseRequest.stringID }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p
                    class="invoice-date"
                    style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                  >
                    <span class="semi-bold">Created On:</span> {{ dateFormatWithTime(new Date()) }}
                  </p>
                </div>
              </div>
            </div>
            <!-- Spacer -->
            <hr
              class="invoice-spacing"
              style="margin-top:25px!important"
            >
          </b-card-body>

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            id="content-div"
            class="invoice-padding pt-0 main__body po__create preview_order_pdf"
          >
            <b-row id="vendorAndDeliveryDetail">
              <b-col cols="md-6">
                <b-table-simple
                  borderless
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-th width="230px">
                        Order From
                      </b-th>
                      <b-td>
                        <span class="text-bold-black">{{ vendorName }}</span>
                        <br>
                        <span class="text-pre">{{ vendorAddress }}
                        </span>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Vendor Tel
                      </b-th>
                      <b-td>{{ companyContact ? companyContact : '-' }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Vendor Fax
                      </b-th>
                      <b-td>{{ companyFax ? companyFax : '-' }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Vendor H/P
                      </b-th>
                      <b-td>{{ companyHP ? companyHP : '-' }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Vendor Email
                      </b-th>
                      <b-td>{{ companyEmail ? companyEmail : '-' }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Vendor Contact Person
                      </b-th>
                      <b-td>{{ salesPersonName ? salesPersonName : '-' }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Payment Terms
                      </b-th>
                      <b-td>{{ paymentTerms ? paymentTerms : '-' }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Delivery Lead Time
                      </b-th>
                      <b-td>{{ deliveryLeadTime ? deliveryLeadTime : '-' }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
              <b-col cols="md-6">
                <b-table-simple
                  borderless
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-th width="230px">
                        Deliver To
                      </b-th>
                      <b-td>
                        <span class="text-bold-black">{{ resolveDeliverToName() }}</span>
                        <br>
                        <span class="text-pre">{{ address }}
                        </span>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Delivery Info
                      </b-th>
                      <b-td><span class="text-pre">{{ deliveryInfo ? deliveryInfo : '-' }}</span></b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Contact Person
                      </b-th>
                      <b-td>{{ mainContactPerson ? mainContactPerson : '-' }}{{ secondContactPerson ? `, ${secondContactPerson}` : '' }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Tel
                      </b-th>
                      <b-td>{{ mainContactPersonContact ? mainContactPersonContact : '-' }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Mobile
                      </b-th>
                      <b-td>{{ backupContact ? backupContact : '-' }} ({{ secondContactPerson ? secondContactPerson : '-' }})</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Fax
                      </b-th>
                      <b-td>{{ contactFax ? contactFax : '-' }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Email
                      </b-th>
                      <b-td>{{ contactEmail ? contactEmail : '-' }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="p-0 item__description_table"
              >
                <!-- Invoice Description: Table -->
                <b-table-simple
                  ref="teamsTable"
                  class="position-relative has_padding"
                  responsive
                  caption-top
                >
                  <b-thead>
                    <b-tr class="group__tag">
                      <b-th>
                        No
                      </b-th>
                      <b-th>
                        Description
                      </b-th>
                      <b-th>
                        Unit
                      </b-th>
                      <b-th>
                        Qty Ordered
                      </b-th>
                      <b-th>
                        Unit Cost (S$)
                      </b-th>
                      <b-th>
                        Amount (S$)
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody
                    class="white-body-background"
                  >
                    <b-tr
                      v-for="(item, index) in chunkItemRequests"
                      :key="index"
                    >
                      <b-td>
                        {{ index + 1 }}
                      </b-td>
                      <b-td style="max-width: 50rem;">
                        <span class="text-bold semi-bold">{{ item.itemName }}</span>
                        <br>
                        <span class="text-pre">{{ item.itemDescription }}</span>
                      </b-td>
                      <td>
                        {{ item.unit }}
                      </td>
                      <b-td>
                        {{ item.quantity }}
                      </b-td>
                      <td>
                        {{ item.unitCost }}
                      </td>
                      <td>
                        {{ numberFormat(item.amount) }}
                      </td>
                    </b-tr>
                    <tr>
                      <td />
                      <td colspan="4">
                        Subtotal
                      </td>
                      <td>
                        {{ pOrder.subTotal ? numberFormat(pOrder.subTotal) : 0.00 }}
                      </td>
                    </tr>
                    <tr>
                      <td />
                      <td colspan="4">
                        GST {{ pOrder.gstPercentage }}%
                      </td>
                      <td>
                        {{ pOrder.gst == '-' ? '-' : (pOrder.gst ? numberFormat(pOrder.gst) : 0.00) }}
                      </td>
                    </tr>
                    <tr>
                      <td />
                      <td colspan="4">
                        Grand Total
                      </td>
                      <td>
                        {{ pOrder.grandTotal ? numberFormat(pOrder.grandTotal) : 0.00 }}
                      </td>
                    </tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Total -->
          <div id="footer-div">
            <b-card-body
              class="invoice-padding pb-0 footer__area"
            >
              <!-- Spacer -->
              <hr class="invoice-spacing">
              <b-row>

                <!-- Col: Sales Persion -->
                <b-col
                  v-for="(signatory, index) of resolveSignatoryAssigns"
                  :key="index"
                  cols="3"
                  md="3"
                  class="mt-md-1 mt-1"
                  order="2"
                  order-md="1"
                >
                  <b-card-text class="mb-0">
                    <span class="font-weight-bold">{{ loas[index].code }}</span>
                    <div class="sign-box">
                      <p>Pending Signature</p>
                    </div>
                    <p class="ml-75 semi-bold">
                      {{ resolveSignatoryName(signatory) }}
                    </p>
                    <p class="ml-75">
                      {{ resolveSignatoryDepartment(signatory) }}
                    </p>
                  </b-card-text>
                </b-col>
                <b-col
                  cols="3"
                  md="3"
                  class="mt-md-1 mt-1"
                  order="2"
                  order-md="1"
                >
                  <b-card-text class="mb-0">
                    <span class="font-weight-bold">Approved by</span>
                    <div class="sign-box">
                      <p>Pending Signature</p>
                    </div>
                    <p class="ml-75 semi-bold">
                      {{ resolveSignatoryName(signatoryAssigns[signatoryOption - 1]) }}
                    </p>
                    <p class="ml-75">
                      {{ resolveSignatoryDepartment(signatoryAssigns[signatoryOption - 1]) }}
                    </p>
                  </b-card-text>
                </b-col>
              </b-row>
            </b-card-body>
            <b-card-body class="invoice-padding pb-0 terms__block">
              <b-row
                class="content-header"
              >

                <!-- Content Left -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <h4>Terms and Conditions</h4>
                  <b-card-text class="mb-0">
                    <p>1. Acknowledgement copy of this order form must be stamped, signed and returned to us for confirmation of acceptance forthwith.</p>
                    <p>2. INVOICE IN DUPLICATE indicating Delivery Note number and date of delivery.</p>
                    <p>3. Credit terms begin at date of receipt of invoice or good whichever is later.</p>
                    <p>4. A higher price than shown on the order must not be charged without our authority.</p>
                    <p>5. Please execute out for goods listed above forthwith. This right is reserved to cancel this order if goods are not supplied within that stipulated above. Goods supplied not in accordance with our specifications will be returned at your own risk and expense.</p>
                    <p>6. We do not hole ourselves responsible for any goods delivered unless a written order duly signed by the authorised official our company can be produced. Suppliers should therefore in their own interest see that such order is obtained before executing order.</p>
                    <p>7. Please email or call us when deliver. Kindly note that our delivery time is from 8:30am - 11:00am or 1pm - 4pm, Monday - Friday excluding Public Holiday.</p>
                  </b-card-text>
                </b-col>
              </b-row>
            </b-card-body>
          </div>
        </div>
      </b-card>
    </div>

    <div v-show="poPreview">
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="12"
        >
          <b-row class="breadcrumbs-top">
            <b-col
              class="content-header-left mb-2"
              cols="12"
              md="12"
            >
              <b-row class="breadcrumbs-top">
                <b-col cols="12">
                  <h1 class="custom-header-title">
                    Preview Purchase Order
                  </h1>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <section class="invoice-preview-wrapper">
        <b-row
          class="invoice-preview"
        >

          <!-- Col: Left (Invoice Container) -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >
            <div>
              <b-card
                class="mb-0"
              >
                <b-row class="invoice-preview">
                  <b-col
                    cols="12"
                    xl="4"
                    md="4"
                    class="text-left"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="link"
                      :disabled="currentFileIndex == 1"
                      @click="changeFileIndex(currentFileIndex - 1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="pt-25"
                        size="18"
                      />
                      <span class="align-middle">Prev</span>
                    </b-button>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="4"
                    md="4"
                    class="text-center"
                  >
                    <b-dropdown
                      id="dropdown-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      split
                    >
                      <template #button-content>
                        <feather-icon
                          icon="FileIcon"
                          size="18"
                          class="align-middle mr-1"
                        />
                        <span class="mr-1">{{ currentFileIndex.toString().padStart(2, "0") }} / {{ attachments ? ((attachments.length + 1).toString().padStart(2, "0")) : '01' }}: {{ selectedFileName }}</span>
                      </template>
                      <b-dropdown-item @click="changeFileIndex(1)">
                        {{ purchaseRequest.caseID }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-for="(file, key) in attachments"
                        :key="key"
                        @click="changeFileIndex(key + 2)"
                      >
                        {{ file ? file.name : '' }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <p
                      v-show="currentFileIndex == 1"
                      class="mt-1"
                    >
                      Purchase Order (PO)
                    </p>
                    <p
                      v-show="currentFileIndex != 1"
                      class="mt-1"
                    >
                      {{ attachments.length ? (attachments[currentFileIndex - 2] ? attachments[currentFileIndex - 2].description : '') : '' }}
                    </p>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="4"
                    md="4"
                    class="text-right"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="link"
                      :disabled="currentFileIndex == (attachments ? (attachments.length + 1) : 1)"
                      @click="changeFileIndex(currentFileIndex + 1)"
                    >
                      <span class="align-middle">Next</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="pt-25"
                        size="18"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </div>
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <div v-show="showAttachment">
                <iframe
                  v-show="showPDF"
                  id="attachmentpdf"
                  width="100%"
                  height="1145"
                />
                <b-img
                  v-show="!showPDF"
                  :src="imageSrc"
                  fluid-grow
                  alt="Fluid-grow image"
                />
              </div>
              <div v-show="!showAttachment">
                <iframe
                  id="docpdf"
                  width="100%"
                  height="1145"
                />
              </div>
            </b-card>
          </b-col>

          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions"
          >
            <b-card>

              <template #header>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="flat-primary"
                  class="primary-button-with-background mr-3"
                >
                  <feather-icon
                    icon="GridIcon"
                    class=""
                    size="20"
                  />
                </b-button>
                <h3 class="align-middle mr-auto">
                  <span class="step-class">Approval Required</span>
                  Ready to Submit?
                </h3>
              </template>

              <b-card-text class="mb-0">
                <span>
                  Please check that the information on this preview page is accurate before proceeding to update the Purchase Order.
                </span>
              </b-card-text>

              <b-form-checkbox
                v-model="secondAgree"
                value="accepted"
                unchecked-value=""
                class="custom-control-primary"
              >
                I confirm that all information is accurate on this form.
              </b-form-checkbox>

              <!-- Button: Send Invoice -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mb-75"
                block
                :disabled="secondAgree == ''"
                @click="finalConfirmation()"
              >
                <feather-icon
                  icon="FeatherIcon"
                  class="mr-50"
                  size="16"
                />
                <span class="align-middle">Sign and Update PO</span>
              </b-button>

              <!-- Button: DOwnload -->
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="flat-primary"
                class="mb-75"
                block
                @click="enableEdit()"
              >
                Back to Editing
              </b-button>
            </b-card>
            <b-card
              no-body
            >
              <b-card-header class="primary-card-header">
                <div class="d-flex align-items-center">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="flat-primary"
                    class="primary-button-with-background mr-3 header-icon"
                  >
                    <feather-icon
                      icon="DollarSignIcon"
                      class=""
                      size="20"
                    />
                  </b-button>
                  <h3 class="align-middle mr-2">
                    Budget
                  </h3>
                </div>

                <div
                  v-if="expenseItems.length"
                  class="d-flex align-items-center"
                >
                  <div
                    class="button-prev"
                  >
                    <b-button
                      :disabled="currentIndex == 0"
                      variant="none"
                      @click="currentIndex -= 1"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="20"
                      />
                    </b-button>
                  </div>
                  <div
                    slot="pagination"
                    class="swiper-pagination"
                  >
                    <span>{{ (currentIndex + 1).toString().padStart(2, 0) }} / {{ expenseItems.length.toString().padStart(2, 0) }}</span>
                  </div>
                  <div
                    class="button-next"
                  >
                    <b-button
                      :disabled="(currentIndex + 1) == expenseItems.length"
                      variant="none"
                      @click="currentIndex += 1"
                    >
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="20"
                      />
                    </b-button>
                  </div>
                </div>
              </b-card-header>
              <b-card-body>
                <b-badge
                  v-if="purchaseRequest.budgetStatus"
                  :variant="`light-${purchaseRequest.budgetStatus == 'allocated' ? 'success' : 'danger'}`"
                  class="text-capitalize-first mb-2"
                >
                  {{ purchaseRequest.budgetStatus }}
                </b-badge>
                <div
                  v-if="expenseItems.length"
                >
                  <div class="mb-1">
                    <p class="no-margin text-bold-black">
                      Budgetary Period
                    </p>
                    <span>{{ expenseItems[currentIndex].budgetPeriod.name || '-' }} ({{ dateFormat(expenseItems[currentIndex].budgetPeriod.start) + ' - ' + dateFormat(expenseItems[currentIndex].budgetPeriod.end) }})</span>
                  </div>
                  <div class="mb-1">
                    <p class="no-margin text-bold-black">
                      Expense Type
                    </p>
                    <span>{{ expenseItems[currentIndex].expenseCategoryType || '-' }}</span>
                  </div>
                  <div class="mb-1">
                    <p class="no-margin text-bold-black">
                      Cost Centre
                    </p>
                    <span>{{ expenseItems[currentIndex].costCenter.name || '-' }}</span>
                  </div>
                  <div class="mb-1">
                    <p class="no-margin text-bold-black">
                      Sub-budget (Expense Category)
                    </p>
                    <span>{{ expenseItems[currentIndex].expenseCategory.code || '-' }} - {{ expenseItems[currentIndex].expenseCategory.name }}</span>
                  </div>
                  <div class="mb-1">
                    <p class="no-margin text-bold-black">
                      Balance
                    </p>
                    <span v-if="canViewThisAction('see-balance', 'BudgetOverview')">{{ expenseItems[currentIndex].subBudget ? (`S$ ${numberFormat(expenseItems[currentIndex].subBudget.remaining)}`) : '-' }}</span>
                    <span v-else>-</span>
                  </div>
                  <div class="mb-1">
                    <p class="no-margin text-bold-black">
                      Allocated Amount
                    </p>
                    <span>S$ {{ numberFormat(expenseItems[currentIndex].allocated) }}</span>
                  </div>
                </div>
                <div
                  v-else
                  class="row"
                >
                  <b-col>
                    <div class="empty_block">
                      <h4>Budget not allocated yet.</h4>
                      <b-button
                        v-if="purchaseRequest.status != 'cancelled' && canViewThisAction('allocate-budget-all-stages', 'BudgetAllocation')"
                        type="button"
                        variant="flat-primary"
                        :disabled="!purchaseRequest.requestType"
                        :to="{ name: 'purchasing-purchase-request-allocate-budget', params: { id: $route.params.id }, query: { type: purchaseRequest.requestType, department: (purchaseRequest.createdBy ? (purchaseRequest.createdBy.department.length ? purchaseRequest.createdBy.department[0]._id : '') : '') } }"
                      >
                        <span class="align-middle">Allocate Budget</span>
                      </b-button>
                    </div>
                  </b-col>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </section>
    </div>
    <div v-show="!poPreview">
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="12"
        >
          <b-row class="breadcrumbs-top">
            <b-col
              class="content-header-left mb-2"
              cols="12"
              md="12"
            >
              <b-row class="breadcrumbs-top">
                <b-col cols="12">
                  <h1 class="custom-header-title">
                    Editing Purchase Order {{ purchaseRequest.caseID }}
                  </h1>
                  <p class="log_info">
                    PRF Created by {{ purchaseRequest.createdBy ? purchaseRequest.createdBy.name : '' }} on {{ dateFormatWithTime(purchaseRequest.createdAt) }}<br>Last updated on {{ dateFormatWithTime(purchaseRequest.updatedAt) }}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!-- Table Container Card -->
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="poCreateForm"
          #default="{invalid}"
        >
          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">STEP 1</span>
                Vendor Information
              </h3>
            </template>
            <b-row>
              <b-col cols="md-6">
                <b-form-group
                  label="Vendor Name"
                  label-for="h-booking-vendorName"
                  label-cols-md="4"
                >
                  <validation-provider
                    name="Vendor Name"
                    vid="vendorName"
                  >
                    <b-form-input
                      id="h-booking-vendorName"
                      v-model="vendorName"
                      name="vendorName"
                      readonly
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="md-6">
                <validation-provider
                  #default="{ errors }"
                  name="Vendor Contact Person"
                  vid="salesPersonName"
                  rules=""
                >
                  <b-form-group
                    label="Vendor Contact Person"
                    label-for="h-event-salesPersonName"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="h-booking-salesPersonName"
                      v-model="salesPersonName"
                      name="salesPersonName"
                      placeholder="-"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="salesPersonNameValidation"
                      class="text-danger"
                    >
                      {{ salesPersonNameError }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="md-6">
                <b-form-group
                  label="Vendor Address*"
                  label-for="h-booking-vendorAddress"
                  label-cols-md="4"
                >
                  <validation-provider
                    name="Vendor Address"
                    vid="address"
                  >
                    <b-form-textarea
                      id="h-booking-vendorAddress"
                      v-model="vendorAddress"
                      name="vendorAddress"
                      placeholder="11 Yishun Industrial St 1, Singapore 768089"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="md-6">
                <validation-provider
                  #default="{ errors }"
                  name="Vendor Tel"
                  vid="companyContact"
                  rules=""
                >
                  <b-form-group
                    label="Vendor Tel"
                    label-for="h-event-companyContact"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="h-booking-companyContact"
                      v-model="companyContact"
                      name="companyContact"
                      placeholder="e.g. +65-99998888"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="companyContactValidation"
                      class="text-danger"
                    >
                      {{ companyContactError }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="md-6">
                <validation-provider
                  #default="{ errors }"
                  name="Payment Terms"
                  vid="paymentTerms"
                  rules="required"
                >
                  <b-form-group
                    label-cols-md="4"
                    label="Payment Terms*"
                    label-for="h-bookings-paymentTerms"
                    :state="(errors.length > 0) ? false : null"
                  >
                    <v-select
                      id="h-bookings-paymentTerms"
                      v-model="paymentTerms"
                      label="text"
                      placeholder="Select an option"
                      :options="paymentTermsOptions"
                      :reduce="text => text.value"
                      :clearable="false"
                    />
                    <small
                      v-if="paymentTermsValidation"
                      class="text-danger"
                    >
                      {{ paymentTermsError }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="md-6">
                <validation-provider
                  #default="{ errors }"
                  name="Vendor H/P"
                  vid="companyHP"
                  rules=""
                >
                  <b-form-group
                    label="Vendor H/P"
                    label-for="h-event-companyHP"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="h-booking-companyHP"
                      v-model="companyHP"
                      name="companyHP"
                      placeholder="-"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="companyHPValidation"
                      class="text-danger"
                    >
                      {{ companyHPError }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="md-6">
                <validation-provider
                  #default="{ errors }"
                  name="Delivery Lead Time"
                  vid="deliveryLeadTime"
                  rules="required"
                >
                  <b-form-group
                    label="Delivery Lead Time*"
                    label-for="h-bookings-deliveryLeadTime"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="h-bookings-deliveryLeadTime"
                      v-model="deliveryLeadTime"
                      name="deliveryLeadTime"
                      placeholder="1 Day"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="deliveryLeadTimeValidation"
                      class="text-danger"
                    >
                      {{ deliveryLeadTimeError }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="md-6">
                <validation-provider
                  #default="{ errors }"
                  name="Vendor FAX"
                  vid="companyFax"
                  rules=""
                >
                  <b-form-group
                    label="Vendor FAX"
                    label-for="h-event-companyFax"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="h-booking-companyFax"
                      v-model="companyFax"
                      name="companyFax"
                      placeholder="-"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="companyFaxValidation"
                      class="text-danger"
                    >
                      {{ companyFaxError }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="md-6">
                <validation-provider
                  #default="{ errors }"
                  name="Vendor Email"
                  vid="companyEmail"
                  rules="email"
                >
                  <b-form-group
                    label="Vendor Email"
                    label-for="h-event-companyEmail"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="h-booking-companyEmail"
                      v-model="companyEmail"
                      name="companyEmail"
                      placeholder="-"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="companyEmailValidation"
                      class="text-danger"
                    >
                      {{ companyEmailError }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">STEP 2</span>
                Delivery Information
              </h3>
            </template>
            <b-row>
              <b-col cols="md-6">
                <validation-provider
                  name="Deliver To"
                  vid="deliverTo"
                  rules=""
                >
                  <b-form-group
                    label-cols-md="4"
                    label-for="h-booking-deliverTo"
                    label="Deliver To*"
                  >
                    <!-- <v-select
                      id="h-bookings-deliverTo"
                      v-model="deliverTo"
                      label="address"
                      placeholder="Select an option"
                      :options="addressOptions"
                      :reduce="adrs => adrs._id"
                      :clearable="false"
                      @input="setAddress"
                    > -->
                    <!-- eslint-disable -->
                      <!-- <template #option="{ businessEntity, address }">
                        <span class="">{{ businessEntity ? businessEntity.name : '' }}</span>
                      </template>

                      <template #selected-option="{ businessEntity, address }">
                        <span class="">{{ businessEntity ? businessEntity.name : '' }}</span>
                      </template> -->
                      <!-- eslint-enable -->
                    <!-- </v-select> -->
                    <div class="demo-inline-spacing roster_group_dropdown deliverToDropdown dropdown-with-icon">
                      <span>{{ resolveDeliverToName() }}</span>
                      <b-dropdown
                        id="dropdown-form-purchase-request"
                        ref="filter_dropdown_all_purchase_request"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        right
                        variant="link"
                      >
                        <template #button-content>
                          <feather-icon
                            icon="FileTextIcon"
                            size="20"
                            class="align-middle mr-50"
                          />
                        </template>
                        <h4>Select from Address Book</h4>
                        <p>Pre-fill your address fields by selecting one of the saved addresses below.</p>
                        <h5>Addresses</h5>
                        <b-dropdown-item
                          v-for="(adrs, key) in addressOptions"
                          :key="key"
                          @click="setAddress(adrs)"
                        >
                          <div>
                            <span class="com__name">{{ adrs.name }}</span>
                            <span>{{ adrs.address }}</span>
                          </div>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="md-6">
                <validation-provider
                  #default="{ errors }"
                  name="Main Contact Person"
                  vid="mainContactPerson"
                  rules="required"
                >
                  <b-form-group
                    label="Main Contact Person*"
                    label-for="h-event-mainContactPerson"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="h-booking-mainContactPerson"
                      v-model="mainContactPerson"
                      name="mainContactPerson"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="mainContactPersonValidation"
                      class="text-danger"
                    >
                      {{ mainContactPersonError }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="md-6">
                <b-form-group
                  label="Address*"
                  label-for="h-booking-address"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Address"
                    vid="address"
                    rules="required"
                  >
                    <b-form-textarea
                      id="h-booking-address"
                      v-model="address"
                      trim
                      placeholder="Address"
                      :state="addressValidation == true ? addressValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="addressValidation"
                      class="text-danger"
                    >
                      {{ addressError }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="md-6">
                <b-row>
                  <b-col cols="md-12">
                    <validation-provider
                      #default="{ errors }"
                      name="Main Contact H/P"
                      vid="mainContactPersonContact"
                      rules="required"
                    >
                      <b-form-group
                        label="Main Contact H/P*"
                        label-for="h-event-mainContactPersonContact"
                        label-cols-md="4"
                      >
                        <b-form-input
                          id="h-booking-mainContactPersonContact"
                          v-model="mainContactPersonContact"
                          name="mainContactPersonContact"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="mainContactPersonContactValidation"
                          class="text-danger"
                        >
                          {{ mainContactPersonContactError }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="md-12">
                    <validation-provider
                      #default="{ errors }"
                      name="Contact Person #2"
                      vid="secondContactPerson"
                      rules=""
                    >
                      <b-form-group
                        label="Contact Person #2"
                        label-for="h-event-secondContactPerson"
                        label-cols-md="4"
                      >
                        <b-form-input
                          id="h-booking-secondContactPerson"
                          v-model="secondContactPerson"
                          name="secondContactPerson"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="secondContactPersonValidation"
                          class="text-danger"
                        >
                          {{ secondContactPersonError }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="md-6">
                <b-form-group
                  label="Delivery Info"
                  label-for="h-booking-deliveryInfo"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Delivery Info"
                    vid="deliveryInfo"
                    rules=""
                  >
                    <b-form-textarea
                      id="h-booking-deliveryInfo"
                      v-model="deliveryInfo"
                      trim
                      placeholder="Delivery Info"
                      :state="deliveryInfoValidation == true ? deliveryInfoValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="deliveryInfoValidation"
                      class="text-danger"
                    >
                      {{ deliveryInfoError }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="md-6">
                <b-row>
                  <b-col cols="md-12">
                    <validation-provider
                      #default="{ errors }"
                      name="Contact Person #2 H/P"
                      vid="backupContact"
                      rules=""
                    >
                      <b-form-group
                        label="Contact Person #2 H/P"
                        label-for="h-event-backupContact"
                        label-cols-md="4"
                      >
                        <b-form-input
                          id="h-booking-backupContact"
                          v-model="backupContact"
                          name="backupContact"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="backupContactValidation"
                          class="text-danger"
                        >
                          {{ backupContactError }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="md-12">
                    <validation-provider
                      #default="{ errors }"
                      name="FAX"
                      vid="contactFax"
                      rules=""
                    >
                      <b-form-group
                        label="FAX"
                        label-for="h-event-contactFax"
                        label-cols-md="4"
                      >
                        <b-form-input
                          id="h-booking-contactFax"
                          v-model="contactFax"
                          name="contactFax"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="contactFaxValidation"
                          class="text-danger"
                        >
                          {{ contactFaxError }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="md-6">
                &nbsp;
              </b-col>
              <b-col cols="md-6">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="contactEmail"
                  rules="email"
                >
                  <b-form-group
                    label="Email"
                    label-for="h-event-contactEmail"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="h-booking-contactEmail"
                      v-model="contactEmail"
                      name="contactEmail"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="contactEmailValidation"
                      class="text-danger"
                    >
                      {{ contactEmailError }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            header-tag="header"
            no-body
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">STEP 3</span>
                Item(s) to Purchase
              </h3>
            </template>
            <b-row>

              <b-col
                cols="12"
              >
                <b-table-simple
                  ref="teamsTable"
                  class="position-relative has_padding create__po"
                  responsive
                  caption-top
                >
                  <b-thead>
                    <b-tr class="group__tag">
                      <b-th>
                        No
                      </b-th>
                      <b-th>
                        Description
                      </b-th>
                      <b-th>
                        Unit
                      </b-th>
                      <b-th>
                        Qty Ordered
                      </b-th>
                      <b-th>
                        Unit Cost (S$)
                      </b-th>
                      <b-th>
                        Amount (S$)
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody
                    class="white-body-background"
                  >
                    <b-tr
                      v-for="(item, index) in poItems"
                      :key="index"
                    >
                      <b-td>
                        {{ index + 1 }}
                      </b-td>
                      <b-td style="max-width: 50rem;">
                        <span class="text-bold-black">{{ item.itemName }}</span>
                        <br>
                        <span class="text-pre">{{ item.itemDescription }}</span>
                      </b-td>
                      <td>
                        {{ item.unit }}
                      </td>
                      <b-td>
                        {{ item.quantity }}
                      </b-td>
                      <td>
                        {{ item.unitCost }}
                      </td>
                      <td>
                        {{ numberFormat(item.amount) }}
                      </td>
                    </b-tr>
                    <!-- <b-tr>
                      <b-td>
                        {{ poItems.length + 1 }}
                      </b-td>
                      <b-td>
                        Delivery
                      </b-td>
                      <td />
                      <b-td>
                        1
                      </b-td>
                      <td>
                        {{ quotation.deliveryCost ? quotation.deliveryCost[vendorIndex] : 0 }}
                      </td>
                      <td>
                        {{ quotation.deliveryCost ? quotation.deliveryCost[vendorIndex] : 0 }}
                      </td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        {{ poItems.length + 2 }}
                      </b-td>
                      <b-td>
                        Discount
                      </b-td>
                      <td />
                      <b-td>
                        1
                      </b-td>
                      <td>
                        {{ quotation.discountList ? quotation.discountList[vendorIndex] : 0 }}
                      </td>
                      <td>
                        {{ quotation.discountList ? quotation.discountList[vendorIndex] : 0 }}
                      </td>
                    </b-tr> -->
                    <tr class="cal_row subtotal_row">
                      <td />
                      <td colspan="4">
                        <span class="semi-bold">Subtotal</span>
                      </td>
                      <td>
                        {{ pOrder.subTotal ? numberFormat(pOrder.subTotal) : 0.00 }}
                      </td>
                    </tr>
                    <tr class="cal_row">
                      <td />
                      <td colspan="4">
                        <span class="semi-bold">GST {{ pOrder.gstPercentage }}%</span>
                      </td>
                      <td>
                        {{ pOrder.gst == '-' ? '-' : (pOrder.gst ? numberFormat(pOrder.gst) : 0.00) }}
                      </td>
                    </tr>
                    <tr class="cal_row last__row">
                      <td />
                      <td colspan="4">
                        <span class="semi-bold">Grand Total</span>
                      </td>
                      <td>
                        <span class="semi-bold">{{ pOrder.grandTotal ? numberFormat(pOrder.grandTotal) : 0.00 }}</span>
                      </td>
                    </tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-card>

          <!-- <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">STEP 5</span>
                Supporting Documents, Files and Images
              </h3>
            </template>
            <b-row>
              <b-col
                cols="12"
              >
                <vue-dropzone
                  id="event-images"
                  ref="myVueDropzone"
                  :options="dropzoneOptions"
                  :use-custom-slot="true"
                  @vdropzone-files-added="processFile"
                  @vdropzone-removed-file="fileRemoved"
                  @vdropzone-error="handleError"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      Drop files here or click to upload
                    </h3>
                    <div class="subtitle">
                      File should not bigger than 5 mb. JPEG, PNG, or PDF only
                    </div>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
                      class="mt-2"
                    >
                      Upload file
                    </b-button>
                  </div>
                </vue-dropzone>
              </b-col>
            </b-row>
          </b-card> -->

          <b-card
            header-tag="header"
            no-body
            class="budget-expense-card mt-2"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3 header-icon"
              >
                <feather-icon
                  icon="DollarSignIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-2">
                <span
                  class="step-class"
                  style="display: block;"
                >
                  STEP 4
                </span>
                Budget
              </h3>
              <b-badge
                v-if="purchaseRequest.budgetStatus"
                :variant="`light-${purchaseRequest.budgetStatus == 'allocated' ? 'success' : 'danger'}`"
                class="text-capitalize-first"
              >
                {{ purchaseRequest.budgetStatus }}
              </b-badge>
              <b-button
                v-if="purchaseRequest.status != 'cancelled' && expenseItems.length && (canViewThisAction('update', 'BudgetAllocation') || canViewThisAction('edit-budget-all-stages', 'BudgetAllocation'))"
                variant="flat-primary"
                class="ml-auto"
                :to="{ name: 'purchasing-purchase-request-update-allocated-budget', params: { parent: $route.params.parent, id: budgetExpense._id }, query: { type: purchaseRequest.requestType } }"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                  size="16"
                />
                <span class="align-middle">Edit Budget</span>
              </b-button>
            </template>
            <div
              v-if="expenseItems.length"
            >
              <budget-expense
                :expense-items.sync="expenseItems"
                :budget-expense.sync="budgetExpense"
              />
            </div>
            <div
              v-else
              class="row"
            >
              <b-col>
                <div class="empty_block">
                  <h4>Budget not allocated yet.</h4>
                  <b-button
                    v-if="purchaseRequest.status != 'cancelled' && canViewThisAction('allocate-budget-all-stages', 'BudgetAllocation')"
                    type="button"
                    variant="flat-primary"
                    :disabled="!purchaseRequest.requestType"
                    :to="{ name: 'purchasing-purchase-request-allocate-budget', params: { id: $route.params.id }, query: { type: purchaseRequest.requestType, department: (purchaseRequest.createdBy ? (purchaseRequest.createdBy.department.length ? purchaseRequest.createdBy.department[0]._id : '') : '') } }"
                  >
                    <span class="align-middle">Allocate Budget</span>
                  </b-button>
                </div>
              </b-col>
            </div>
          </b-card>

          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">STEP 5</span>
                Signatories
              </h3>
            </template>
            <div class="signatories_block">
              <b-row>
                <b-col cols="md-6">
                  <validation-provider
                    #default="{ errors }"
                    name="Number of Signatory Required"
                    vid="signatoryOption"
                    rules="required"
                  >
                    <b-form-group
                      label="Number of Signatory Required*"
                      label-for="h-activity-type-signatoryOption"
                      label-cols-md="5"
                      :state="(errors.length > 0 || signatoryOptionValidation) ? false : null"
                    >
                      <v-select
                        id="h-activity-type-signatoryOption"
                        v-model="signatoryOption"
                        label="title"
                        :options="signatoryOptions"
                        :reduce="title => title.code"
                        :clearable="false"
                        @input="changeSignatory()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="signatoryOptionValidation"
                        class="text-danger"
                      >
                        {{ signatoryOptionError }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="md-6" />
              </b-row>
              <hr class="dividerHR">
              <div v-if="signatoryOption">
                <b-row
                  v-for="(opt, key) in loas"
                  :key="key"
                >
                  <b-col
                    v-if="signatoryOption > (key + 1)"
                    cols="md-6"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name=""
                      :vid="'event-custom-field-' + key"
                      rules="required"
                    >
                      <b-form-group
                        label-cols-md="5"
                        :label="opt.title"
                        :label-for="'event-custom-field-' + key"
                        :state="(errors.length > 0) ? false : null"
                      >
                        <v-select
                          id="h-bookings-dutyOfficer"
                          v-model="signatoryAssigns[key]"
                          label="name"
                          placeholder="Select an option"
                          :disabled="key < 1"
                          :options="userOptions"
                          :reduce="name => name._id"
                          :clearable="false"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="md-6"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name=""
                      vid="event-custom-field-n"
                      rules="required"
                    >
                      <b-form-group
                        label-cols-md="5"
                        :label="signatoryOption + '. Approved by'"
                        label-for="event-custom-field-n"
                        :state="(errors.length > 0) ? false : null"
                      >
                        <v-select
                          id="h-bookings-dutyOfficer"
                          v-model="signatoryAssigns[signatoryOption - 1]"
                          label="name"
                          placeholder="Select an option"
                          :options="userOptions"
                          :reduce="name => name._id"
                          :clearable="false"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-card>

          <b-nav class="wrap-border save-nav">
            <li
              class="nav-item ml-auto"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                @click="cancelEdit()"
              >
                Cancel
              </b-button>
            </li>
            <li
              class="nav-item mr-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="success"
                :disabled="invalid || !deliverTo"
                @click="enablePreview()"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span class="align-middle">Preview PO Before Updating</span>
              </b-button>
            </li>
          </b-nav>
        </validation-observer>
      </b-form>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BForm, BButton, BNav, VBToggle, BCardBody, BCardText,
  BTableSimple, BTbody, BTr, BTh, BTd, BFormCheckbox, BThead, BDropdown, BDropdownItem, BImg, BCardHeader, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import vue2Dropzone from 'vue2-dropzone'
import vSelect from 'vue-select'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { heightTransition } from '@core/mixins/ui/transition'
import store from '@/store/index'
import moment from 'moment'
// import { jsPDF } from 'jspdf'
// import html2canvas from 'html2canvas'
// import html2pdf from 'html2pdf.js'
import BudgetExpense from './BudgetExpense.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BNav,
    BCardBody,
    BCardText,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BThead,
    BImg,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BCardHeader,
    BBadge,
    vSelect,
    // vueDropzone: vue2Dropzone,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BudgetExpense,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      poPreview: false,
      user: store.state.auth.userData,
      todayMonthYear: moment().format('YYYYMM'),
      requesterName: store.state.auth.userData.name,
      deliveryDate: '',
      requesterDepartment: store.state.auth.userData.department.length ? store.state.auth.userData.department[0].name : '',
      deliverTo: '',
      companyContact: '',
      companyContactError: 'Valid Delivery Date is required',
      companyContactValidation: false,
      paymentTerms: '',
      paymentTermsError: 'Valid Delivery Date is required',
      paymentTermsValidation: false,
      companyHP: '',
      companyHPError: 'Valid Delivery Date is required',
      companyHPValidation: false,
      deliveryLeadTime: '',
      deliveryLeadTimeError: 'Valid Delivery Date is required',
      deliveryLeadTimeValidation: false,
      companyFax: '',
      companyFaxError: 'Valid Delivery Date is required',
      companyFaxValidation: false,
      companyEmail: '',
      companyEmailError: 'Valid Delivery Date is required',
      companyEmailValidation: false,
      vendorAddress: '',
      vendorAddressError: 'Valid Delivery Date is required',
      vendorAddressValidation: false,
      mainContactPerson: '',
      mainContactPersonError: 'Valid Delivery Date is required',
      mainContactPersonValidation: false,
      mainContactPersonContact: '',
      mainContactPersonContactError: 'Valid Delivery Date is required',
      mainContactPersonContactValidation: false,
      contactFax: '',
      contactFaxError: 'Valid Delivery Date is required',
      contactFaxValidation: false,
      secondContactPerson: '',
      secondContactPersonError: 'Valid Delivery Date is required',
      secondContactPersonValidation: false,
      backupContact: '',
      backupContactError: 'Valid Delivery Date is required',
      backupContactValidation: false,
      contactEmail: '',
      contactEmailError: 'Valid email is required',
      contactEmailValidation: false,
      purpose: '',
      address: '',
      deliveryInfo: '',
      requestType: 'Purchase Requisition Form (PRF)',
      saveType: 'preview',
      company: '',
      salesPersonName: '',
      vendorName: '',

      requestedBy: store.state.auth.userData._id,
      confirmedBy: '',
      approvedBy: '',
      signatoryOption: 3,
      signatoryOptionOld: 3,
      attachments: [],
      itemsMasterOptions: [],
      itemRequests: [{
        itemsMaster: '',
        description: '',
        quantity: '',
        unit: '',
        totalCost: '',
      }],
      secondAgree: '',
      filteredOptions: [],
      salesPersonNameError: 'Valid Delivery Date is required',
      salesPersonNameValidation: false,
      deliverToError: 'Valid Deliver To is required',
      deliverToValidation: false,
      deliveryInfoError: 'Valid Delivery Info is required',
      deliveryInfoValidation: false,
      purposeError: 'Valid Justification / Purpose is required',
      purposeValidation: false,
      addressError: 'Valid Address is required',
      addressValidation: false,
      requestTypeError: 'Valid Type of Request is required',
      requestTypeValidation: false,
      confirmedByError: 'Valid user is required',
      confirmedByValidation: false,
      approvedByError: 'Valid user is required',
      approvedByValidation: false,
      requestedByError: 'Valid user is required',
      requestedByValidation: false,
      userOptions: [],
      vendorOptions: [],
      companyOptions: [
        { text: 'Paramount Zen Pte. Ltd.', value: 'Paramount Zen Pte. Ltd.', description: '1 One Raffles Place #19-01, Office Tower One, Singapore 048616' },
      ],
      addressOptions: [],
      businessEntityOptions: [],
      unitOptions: process.env.VUE_APP_UNIT_LIST.split(','),
      signatoryAssigns: [],
      signatoryAssignsError: 'Valid Signatory is required',
      signatoryAssignsValidation: false,
      signatoryOptionError: 'Valid Number of Signatory is required',
      signatoryOptionValidation: false,
      companyError: 'Valid Entity/Company is required',
      companyValidation: false,
      optionsChekcbox: [
        { text: 'ISO 9001 Quality Management', value: 'ISO 9001 Quality Management' },
        { text: 'ISO 14001 Environment Management', value: 'ISO 14001 Environment Management' },
        { text: 'OHSAS 18001 / ISO 45001 Occupational Safety & Health Management', value: 'OHSAS 18001 / ISO 45001 Occupational Safety & Health Management' },
        { text: 'No Certificate', value: 'No Certificate' },
        { text: 'Others', value: 'Others' },
      ],
      requestTypeOptions: [
        { text: 'Purchase Requisition Form (PRF)', value: 'Purchase Requisition Form (PRF)' },
        { text: 'Capital Expenditure Requisition Form (CAPEX)', value: 'Capital Expenditure Requisition Form (CAPEX)' },
      ],
      quantities: [],
      vendorIndex: 0,
      signatoryOptions: [
        { title: '3', code: '3' },
        { title: '4', code: '4' },
        { title: '5', code: '5' },
        { title: '6', code: '6' },
        { title: '7', code: '7' },
        { title: '8', code: '8' },
        { title: '9', code: '9' },
      ],
      purposeOptions: [
        { title: 'Daily Use', code: 'Daily Use' },
        { title: 'Kitchen Use', code: 'Kitchen Use' },
        { title: 'Recurring', code: 'Recurring' },
      ],
      paymentTermsOptions: [
        { text: '60 Days', value: '60 Days' },
        { text: '45 Days', value: '45 Days' },
        { text: '30 Days', value: '30 Days' },
        { text: '15 Days', value: '15 Days' },
        { text: '7 Days', value: '7 Days' },
        { text: 'C.O.D', value: 'cod' },
        { text: 'Others', value: 'others' },
      ],
      deliveryLeadTimeOptions: [
        { text: '1 Day', value: '1 Day' },
        { text: '1 Week', value: '1 Week' },
        { text: '1 Month', value: '1 Month' },
      ],
      loas: [
        { title: '1. Prepared by', code: 'Prepared by' },
        { title: '2. Verified by', code: 'Verified by' },
        { title: '3. Recommended by', code: 'Recommended by' },
        { title: '4. Agreed by', code: 'Agreed by' },
        { title: '5. Certified by', code: 'Certified by' },
        { title: '6. Confirmed by', code: 'Confirmed by' },
        { title: '7. Supported by', code: 'Supported by' },
        { title: '8. Validated by', code: 'Validated by' },
      ],
      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', minDate: 'today',
      },
      flatPickrConfigWithTime: {
        wrap: true, enableTime: true, dateFormat: 'd/m/Y, H:i', minDate: 'today',
      },
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        acceptedFiles: 'image/jpeg,image/png,application/pdf',
        maxFilesize: 5,
        addRemoveLinks: true,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
        // maxFiles: 1,
      },
      itemsString: '',
      fileName: 'compressed.tracemonkey-pldi-09.pdf',
      path: '/lib/pdfjs/web/viewer.html',
      pdfData: '',
      chunkItemRequests: [],
      chunksOfItemRequests: [],
      pdfTotalPage: 0,
      elHeight: 0,
      gapHeight: 0,
      showPDF: true,
      showAttachment: false,
      selectedFileName: 'PRF-000055',
      currentFileIndex: 1,
      imageSrc: '',

      // validation rules
      required,
      pOrder: {},
      poItems: [],
      purchaseRequest: {},
      budgetExpense: {},
      expenseItems: [],
      currentIndex: 0,
    }
  },
  computed: {
    resolveSignatoryAssigns() {
      return [...this.signatoryAssigns].slice(0, -1)
    },
  },

  created() {
    // this.$http.get('directory/teams/respond-with/user-options')
    //   .then(response => {
    //     this.userOptions = response.data.userOptions ?? []
    //   })
    //   .catch(error => {
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //   })
    // for (let i = 1; i <= 100; i += 1) {
    //   this.quantities[i] = i
    // }
    // this.$http.post('purchase/vendors/find-user')
    //   .then(response => {
    //     this.user = response.data.data ?? []
    //     this.requesterName = this.user.name
    //     this.requesterDepartment = this.user.department[0].name
    //   })
    //   .catch(error => {
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //   })
    this.$http.get('purchase/purchase-request/create/extra-options')
      .then(response => {
        this.itemsMasterOptions = response.data.itemsMasterOptions ?? []
        // this.addressOptions = response.data.addressOptions ?? []
        this.businessEntityOptions = response.data.businessEntityOptions ?? []
        this.userOptions = response.data.userOptions ?? []
        this.businessEntityOptions.forEach(businessEntity => {
          if (businessEntity.addresses.length) {
            businessEntity.addresses.forEach(address => {
              const adrs = { ...businessEntity, address: address.address }
              this.addressOptions.push(adrs)
            })
          } else {
            const adrs = { ...businessEntity, address: '' }
            this.addressOptions.push(adrs)
          }
        })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    this.$http.get(`purchase/purchase-order/purchase-request/${this.$route.params.parent}/detail`)
      .then(response => {
        this.purchaseRequest = response.data.purchaseRequest || {}
        this.budgetExpense = response.data.budgetExpense
        this.expenseItems = response.data.expenseItems
        this.poItems = response.data.poItems || []
        this.pOrder = response.data.purchaseOrder || {}
        // this.setData()

        // const vendor = this.quotation.comparedVendors.find(o => o.id._id === this.quotation.recommendedProvider)

        // this.vendorIndex = this.quotation.comparedVendors.findIndex(o => o.id._id === this.quotation.recommendedProvider)
        this.vendorName = this.pOrder.vendorName
        this.salesPersonName = this.pOrder.salesPersonName
        this.vendorAddress = this.pOrder.vendorAddress
        this.companyContact = this.pOrder.companyContact
        this.paymentTerms = this.pOrder.paymentTerms
        this.companyHP = this.pOrder.companyHP
        this.deliveryLeadTime = this.pOrder.deliveryLeadTime
        this.companyFax = this.pOrder.companyFax
        this.companyEmail = this.pOrder.companyEmail

        this.deliverTo = this.pOrder.deliverTo._id
        this.deliveryInfo = this.pOrder.deliveryInfo
        this.address = this.pOrder.address
        this.mainContactPerson = this.pOrder.mainContactPerson
        this.mainContactPersonContact = this.pOrder.mainContactPersonContact
        this.secondContactPerson = this.pOrder.secondContactPerson
        this.backupContact = this.pOrder.backupContact
        this.contactFax = this.pOrder.contactFax
        this.contactEmail = this.pOrder.contactEmail

        this.signatoryOption = this.pOrder.signatories.length
        this.signatoryOptionOld = this.signatoryOption
        this.pOrder.signatories.forEach(arrayItem => {
          this.signatoryAssigns.push(arrayItem.user._id)
        })

        this.selectedFileName = this.purchaseRequest.caseID

        this.poItems.forEach((item, index) => {
          if (index === 0) {
            this.itemsString = item.itemName
          } else {
            this.itemsString += `, ${item.itemName}`
          }
        })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  // destroyed() {
  //   window.removeEventListener('resize', this.initTrHeight)
  // },
  beforeRouteLeave(to, from, next) {
    const bcLength = store.state.breadcrumb.breadcrumbs.length
    if (bcLength > 2) {
      store.commit('breadcrumb/REMOVE_BREADCRUMB')
    }
    next()
  },
  methods: {
    canViewThisAction,
    saveAsDraft() {
      this.saveType = 'draft'
      this.submitForm()
    },
    cancelEdit() {
      this.$swal({
        title: 'Are you sure?',
        html: 'Selecting Cancel will get back you to previous page and cancel the PO edit process.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Cancel!',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'purchasing-purchase-request-show', params: { id: this.$route.params.parent } })
          }
        })
    },
    base64ToUint8Array(base64) {
      const raw = atob(base64)
      const uint8Array = new Uint8Array(raw.length)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < raw.length; i++) {
        uint8Array[i] = raw.charCodeAt(i)
      }
      return uint8Array
    },
    changeFileIndex(key) {
      this.currentFileIndex = key
      if (key === 1) {
        this.selectedFileName = this.purchaseRequest.caseID
        // eslint-disable-next-line func-names
        // pdfViewerFrame.onload = () => {
        //   pdfViewerFrame.contentWindow.PDFViewerApplication.open(this.pdfDataJS)
        // }
        // this.showFirst = false
        // const pdfViewerFrame = document.getElementById('docpdf2')
        // pdfViewerFrame.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${this.pdfDataJS}`)
        this.showAttachment = false
      } else {
        this.selectedFileName = this.attachments[key - 2].name
        if (this.attachments[key - 2].type.includes('pdf')) {
          // eslint-disable-next-line func-names
          // pdfViewerFrame.onload = () => {
          //   pdfViewerFrame.contentWindow.PDFViewerApplication.open(this.attachments[key - 2].data)
          // }
          const base64Raw = this.attachments[key - 2].data.split(',')[1]
          const pdfDataJS = this.base64ToUint8Array(base64Raw)
          const pdfViewerFrame2 = document.getElementById('attachmentpdf')
          // pdfViewerFrame2.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${pdfDataJS}`)
          // eslint-disable-next-line func-names
          pdfViewerFrame2.onload = () => {
            pdfViewerFrame2.contentWindow.PDFViewerApplication.open(pdfDataJS)
          }
          pdfViewerFrame2.setAttribute('src', '/lib/pdfjs/web/viewer.html?file=')
          this.showPDF = true
        } else {
          this.imageSrc = this.attachments[key - 2].data
          this.showPDF = false
        }
        this.showAttachment = true
      }
    },
    async enablePreview() {
      const newMeta = {
        active: true,
        text: 'Preview Purchase Order',
      }
      store.commit('breadcrumb/UPDATE_BREADCRUMB', newMeta)
      store.commit('appConfig/UPDATE_LOADER', true)
      const signatoryArray = []
      this.signatoryAssigns.forEach((user, key) => {
        let type = `${key + 1}. ${this.loas[key].code}`
        if (key > 0 && (key + 1) === this.signatoryAssigns.length) {
          type = `${this.signatoryAssigns.length}. Approved by`
        }
        const userObj = {
          name: this.resolveSignatoryName(user),
          department: this.resolveSignatoryDepartment(user),
          type,
        }
        signatoryArray.push(userObj)
      })
      const pOrderObject = {
        signatories: signatoryArray,
        createdAt: this.pOrder.createdAt,
        vendorName: this.vendorName,
        vendorAddress: this.vendorAddress,
        companyContact: this.companyContact,
        companyFax: this.companyFax,
        companyHP: this.companyHP,
        companyEmail: this.companyEmail,
        salesPersonName: this.salesPersonName,
        paymentTerms: this.paymentTerms,
        deliveryLeadTime: this.deliveryLeadTime,
        deliverTo: this.resolveDeliverToName(),
        address: this.address,
        deliveryInfo: this.deliveryInfo,
        mainContactPerson: this.mainContactPerson,
        secondContactPerson: this.secondContactPerson,
        mainContactPersonContact: this.mainContactPersonContact,
        backupContact: this.backupContact,
        contactFax: this.contactFax,
        contactEmail: this.contactEmail,
        subTotal: this.pOrder.subTotal,
        gstPercentage: this.pOrder.gstPercentage,
        // eslint-disable-next-line eqeqeq, no-nested-ternary
        gst: this.pOrder.gst,
        grandTotal: this.pOrder.grandTotal,
      }
      const formData = new FormData()
      formData.append('url', `${window.location.origin}/purchase-request/po/preview`)
      // formData.append('url', `${window.location.origin}/purchase-request/download/po/65599c5db44ced55532da059`)
      formData.append('landscape', false)
      const localStorageObj = {
        purchaseRequest: this.purchaseRequest,
        pOrder: pOrderObject,
        poItems: this.poItems,
      }
      formData.append('localStorageObj', JSON.stringify(localStorageObj))

      this.$http.post('purchase/vendor/evaluation/pdf/download', formData, {
        headers: { Accept: 'application/pdf' },
        responseType: 'arraybuffer',
      })
        .then(response => {
          const uint8View = new Uint8Array(response.data)
          const pdfViewerFrame = document.getElementById('docpdf')
          // eslint-disable-next-line func-names
          pdfViewerFrame.onload = () => {
            pdfViewerFrame.contentWindow.PDFViewerApplication.open(uint8View)
          }
          pdfViewerFrame.setAttribute('src', '/lib/pdfjs/web/viewer.html?file=')
          this.currentFileIndex = 1
          // this.selectedFileName = this.companyName
          this.showAttachment = false
          window.scrollTo(0, 0)
          this.poPreview = true
          store.commit('appConfig/UPDATE_LOADER', false)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      // const vm = this
      // await this.calculateTotalPage()
      // const element = document.getElementById('pdfGenerateDiv')
      // const opt = {
      //   margin: 1,
      //   filename: 'myfile.pdf',
      //   pagebreak: { mode: ['avoid-all', 'css', 'legacy'], after: '.page-section' },
      //   image: { type: 'jpeg', quality: 1 },
      //   html2canvas: {
      //     useCORS: true,
      //     scale: 2,
      //     dpi: 192,
      //     // windowWidth: 1024,
      //     letterRendering: true,
      //   },
      //   jsPDF: { unit: 'pt', format: 'a4', orientation: 'p' },
      // }

      // html2pdf().set(opt).from(element).toPdf()
      //   .get('pdf')
      //   .then(pdf => {
      //     vm.pdfData = pdf.output('datauristring')
      //     const base64Raw = vm.pdfData.split(',')[1]
      //     const pdfDataJS = vm.base64ToUint8Array(base64Raw)
      //     const pdfViewerFrame = document.getElementById('docpdf')
      //     // eslint-disable-next-line func-names
      //     pdfViewerFrame.onload = () => {
      //       pdfViewerFrame.contentWindow.PDFViewerApplication.open(pdfDataJS)
      //     }
      //     pdfViewerFrame.setAttribute('src', '/lib/pdfjs/web/viewer.html?file=')
      //     vm.currentFileIndex = 1
      //     vm.selectedFileName = vm.purchaseRequest.caseID
      //     vm.showAttachment = false
      //     window.scrollTo(0, 0)
      //     vm.poPreview = true
      //     store.commit('appConfig/UPDATE_LOADER', false)
      //   })
    },
    async calculateTotalPage() {
      await this.$nextTick()
      this.chunksOfItemRequests = []
      this.chunkItemRequests = []
      this.pdfTotalPage = 0
      const logoHeight = document.getElementById('main-header').offsetHeight
      const vendorAndDeliveryDetailHeight = document.getElementById('vendorAndDeliveryDetail').offsetHeight
      const footerHeight = document.getElementById('footer-div').offsetHeight
      let contentHeight = 0
      let totalItems = this.poItems.length
      let i = 0
      let sliceStart = 0
      let sliceEnd = 10
      if (totalItems > 10) {
        sliceEnd = 14
      }
      while (totalItems > 0) {
        // contentHeight = 1000 - (logoHeight + vendorAndDeliveryDetailHeight + footerHeight)
        if (i === 0) {
          contentHeight = 1000 - (logoHeight + vendorAndDeliveryDetailHeight + footerHeight)
        } else {
          contentHeight = 1000 - (logoHeight + footerHeight)
        }
        // console.log(contentHeight)
        this.chunkItemRequests = this.poItems.slice(sliceStart, sliceEnd)
        // this.$nextTick(() => {
        //   console.log(document.getElementById('content-div').offsetHeight)
        // })
        // eslint-disable-next-line no-await-in-loop
        await this.$nextTick()
        let tableHeight = document.getElementById('content-div').offsetHeight - vendorAndDeliveryDetailHeight
        // console.log(tableHeight)
        if (tableHeight > contentHeight) {
          contentHeight += footerHeight
          // contentHeight += vendorAndDeliveryDetailHeight
        }
        // // console.log(tableHeight)
        // while (tableHeight > contentHeight) {
        //   sliceEnd -= 1
        //   // console.log(contentHeight, tableHeight)
        //   this.chunkItemRequests = this.poItems.slice(sliceStart, sliceEnd)
        //   // console.log(sliceStart, sliceEnd)
        //   // eslint-disable-next-line no-await-in-loop
        //   await this.$nextTick()
        //   tableHeight = document.getElementById('content-div').offsetHeight - vendorAndDeliveryDetailHeight
        //   // console.log(tableHeight, contentHeight)
        // }
        while (tableHeight > contentHeight) {
          sliceEnd -= 1
          // console.log(contentHeight, tableHeight)
          // eslint-disable-next-line eqeqeq
          if (sliceStart == sliceEnd) {
            tableHeight = 0
          } else {
            this.chunkItemRequests = this.poItems.slice(sliceStart, sliceEnd)
            // eslint-disable-next-line no-await-in-loop
            await this.$nextTick()
            tableHeight = document.getElementById('content-div').offsetHeight - vendorAndDeliveryDetailHeight
          }
        }
        // console.log(tableHeight)
        // console.log(sliceEnd - sliceStart)
        // console.log(this.chunkItemRequests.length)
        this.chunksOfItemRequests.push(this.chunkItemRequests)
        this.pdfTotalPage += 1
        i += 1
        sliceStart = sliceEnd
        sliceEnd += 17
        totalItems -= this.chunkItemRequests.length
      }

      // if (this.pdfTotalPage === 1) {
      //   contentHeight = 940 - (logoHeight + vendorAndDeliveryDetailHeight + footerHeight)
      // } else {
      //   contentHeight = 940 - (logoHeight + footerHeight)
      // }
      // const finalHeight = document.getElementById('content-div').offsetHeight
      // if (finalHeight > contentHeight) {
      //   this.pdfTotalPage += 1
      // }
      let remainingHeight = 0
      const dHeight = 1080
      // if (this.isMobile()) {
      //   dHeight = 1120
      // }
      let finalDataHeight = 0
      if (this.pdfTotalPage === 1) {
        finalDataHeight = document.getElementById('content-div').offsetHeight
      } else {
        finalDataHeight = document.getElementById('content-div').offsetHeight - vendorAndDeliveryDetailHeight
      }
      this.gapHeight = 0
      remainingHeight = dHeight - (logoHeight + footerHeight + finalDataHeight)
      if (remainingHeight > 0) {
        this.gapHeight = remainingHeight
      }
      if (finalDataHeight >= (dHeight - (logoHeight + footerHeight))) {
        this.pdfTotalPage += 1
        this.gapHeight = dHeight - (logoHeight + footerHeight)
        // console.log(this.gapHeight)
      }
    },
    enableEdit() {
      store.commit('breadcrumb/REMOVE_BREADCRUMB')
      this.poPreview = false
    },
    resolveEntityName() {
      const entity = this.businessEntityOptions.find(o => o._id === this.company)
      if (entity) {
        return entity.name
      }

      return ''
    },
    resolveDeliverToName() {
      const businessEntity = this.businessEntityOptions.find(o => o._id === this.deliverTo)
      if (businessEntity) {
        return businessEntity.name
      }

      return ''
    },

    // resolveConfirmedBy() {
    //
    //   const user = this.userOptions.find(o => o._id === this.confirmedBy)
    //   if (user) {
    //     return (
    //       <div>
    //         <span>{user.name}</span>
    //         <br />
    //         <span>{user.department.name}</span>
    //       </div>
    //     )
    //   }

    //   return ''
    // },
    resolveSignatoryName(id) {
      const user = this.userOptions.find(o => o._id === id)
      if (user) {
        return user.name
      }

      return ''
    },
    resolveSignatoryDepartment(id) {
      const user = this.userOptions.find(o => o._id === id)
      if (user) {
        return user.department.length ? user.department[0].name : ''
      }

      return ''
    },
    resolveApprovedByName() {
      const user = this.userOptions.find(o => o._id === this.approvedBy)
      if (user) {
        return user.name
      }

      return ''
    },
    resolveApprovedByDepartment() {
      const user = this.userOptions.find(o => o._id === this.approvedBy)
      if (user) {
        return user.department.length ? user.department[0].name : ''
      }

      return ''
    },
    processFile(file) {
      const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
      file.forEach(element => {
        if (acceptedTypes.includes(element.type)) {
          if (element.size <= 5 * 1024 * 1024) {
            const exist = this.attachments.find(o => o.name === element.name && o.size === (element.size / (1024 * 1024)).toFixed(2))
            if (exist) {
              setTimeout(() => {
                if (element.previewElement) {
                  element.previewElement.remove()
                }
              }, 500)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Duplicate File',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                const fileObj = {}
                fileObj.name = element.name
                fileObj.description = ''
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                this.attachments.push(fileObj)
              }
            }
          }
        }
      })
    },
    addPurchaseRequest() {
      this.poItems.push({
        itemsMaster: '',
        description: '',
        quantity: '',
        unit: '',
        totalCost: '',
      })

      // this.$nextTick(() => {
      //   this.trAddHeight(this.$refs.row[0].offsetHeight)
      // })
    },
    getSubTotal() {
      const vendor = this.quotation.comparedVendors ? this.quotation.comparedVendors[this.vendorIndex] : {}
      if (!vendor) {
        return '0.00'
      }
      let total = 0
      // this.poItems.forEach(item => {
      //   total += item.unitCost[this.vendorIndex] ? item.quantity * item.unitCost[this.vendorIndex] : 0
      // })
      if (vendor.code === 'GST Inclusive') {
        this.poItems.forEach(item => {
          total += item.unitCost[this.vendorIndex] ? item.quantity * item.unitCost[this.vendorIndex] : 0
        })
        total -= this.getGST()
      } else {
        this.poItems.forEach(item => {
          total += item.unitCost[this.vendorIndex] ? item.quantity * item.unitCost[this.vendorIndex] : 0
        })
      }

      const discount = this.quotation.discountList ? this.quotation.discountList[this.vendorIndex] : 0
      const dCost = this.quotation.deliveryCost ? this.quotation.deliveryCost[this.vendorIndex] : 0

      total = total - discount + parseFloat(dCost)
      return Number.isNaN(total) ? '0.00' : total.toFixed(2)
    },
    getGST() {
      const vendor = this.quotation.comparedVendors ? this.quotation.comparedVendors[this.vendorIndex] : {}
      if (!vendor) {
        return '-'
      }
      if (vendor.code === 'No GST') {
        return '-'
      }

      if (vendor.code === 'GST Inclusive') {
        let gstableAmount = 0
        this.poItems.forEach(item => {
          gstableAmount += (item.gst[this.vendorIndex] && item.gst[this.vendorIndex] === 'yes') ? item.quantity * (item.unitCost[this.vendorIndex] ? item.unitCost[this.vendorIndex] : 0) : 0
        })
        // eslint-disable-next-line no-mixed-operators
        const gstAmount = ((gstableAmount / (100 + parseFloat(this.quotation.gstPercentage))) * parseFloat(this.quotation.gstPercentage))
        return Number.isNaN(gstAmount) ? '0.00' : gstAmount.toFixed(2)
      }

      let total = 0

      this.poItems.forEach(item => {
        total += (item.gst[this.vendorIndex] && item.gst[this.vendorIndex] === 'yes') ? item.quantity * (item.unitCost[this.vendorIndex] ? item.unitCost[this.vendorIndex] : 0) : 0
      })

      // eslint-disable-next-line operator-assignment
      total = ((this.quotation.gstPercentage / 100) * total)

      return Number.isNaN(total) ? '0.00' : total.toFixed(2)
    },
    getGrandTotal() {
      return ((this.getGST() === '-' ? 0 : parseFloat(this.getGST())) + parseFloat(this.getSubTotal())).toFixed(2)
    },
    changeItemsMaster(index) {
      this.poItems[index].description = this.itemsMasterOptions[index].description
      this.poItems[index].unit = this.itemsMasterOptions[index].unit
    },
    resolveItemCounter(page, index) {
      let count = 0
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < (page - 1); i++) {
        count += this.chunksOfItemRequests[i].length
      }

      count += index + 1

      return count
    },
    removePurchaseRequest(index) {
      this.poItems.splice(index, 1)
      if (this.poItems.length > 1) {
        this.poItems.splice(index, 1)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'This have to have atleast one row.',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      }
      // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    fileRemoved(file) {
      if (file.dataURL) {
        this.attachments = this.attachments.filter(element => element.data !== file.dataURL)
      } else {
        this.attachments = this.attachments.filter(element => element.name !== file.name)
      }

      const previewLength = document.getElementsByClassName('dz-preview').length
      if (!previewLength) {
        this.$refs.myVueDropzone.removeAllFiles()
      }
    },
    handleError(file, message) {
      this.$refs.myVueDropzone.removeFile(file)
      if (message !== 'Upload canceled.') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    setAddress(adrs) {
      this.deliverTo = adrs._id
      this.address = adrs.address
    },
    setMasterItem(suggestionItem, suggestionIndex, index) {
      this.poItems[index].itemsMaster = suggestionItem.item.description
      const masterItem = this.itemsMasterOptions.find(o => o.description === suggestionItem.item.description)
      if (masterItem) {
        this.poItems[index].unit = masterItem.unit
      }
    },
    suggestOnInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }
      const filteredData = this.itemsMasterOptions.filter(item => item.description.toLowerCase().indexOf(text.toLowerCase()) > -1)

      this.filteredOptions = [{
        data: filteredData,
      }]
    },
    resolveNextSignatoryName() {
      const user = this.userOptions.find(o => o._id === this.signatoryAssigns[1])
      if (user) {
        return user.name
      }

      return ''
    },
    async changeSignatory() {
      // eslint-disable-next-line radix
      this.signatoryOption = parseInt(this.signatoryOption)
      if (this.signatoryOptionOld > this.signatoryOption) {
        await this.$nextTick()
        this.$swal({
          title: 'Are You Sure?',
          html: 'You are changing the number of signatory.',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          allowOutsideClick: false,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure.",
          customClass: {
            confirmButton: 'btn btn-danger ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              this.signatoryOptionOld = this.signatoryOption
              // eslint-disable-next-line radix
              const intOption = parseInt(this.signatoryOption)
              if (this.signatoryAssigns.length > intOption) {
                this.signatoryAssigns = this.signatoryAssigns.slice(0, intOption)
              }
            } else {
              this.signatoryOption = this.signatoryOptionOld
              // eslint-disable-next-line radix
              const intOption = parseInt(this.signatoryOption)
              if (this.signatoryAssigns.length > intOption) {
                this.signatoryAssigns = this.signatoryAssigns.slice(0, intOption)
              }
            }
          })
      } else {
        this.signatoryOptionOld = this.signatoryOption
        // eslint-disable-next-line radix
        const intOption = parseInt(this.signatoryOption)
        if (this.signatoryAssigns.length > intOption) {
          this.signatoryAssigns = this.signatoryAssigns.slice(0, intOption)
        }
      }
    },

    finalConfirmation() {
      let title = 'Update PO and Submit for Approval?'
      let message = `You are proceeding to update and sign this PO. The PO will then be sent to the next assigned signatory (${this.resolveNextSignatoryName()}) for signing.`

      if (this.user._id !== this.purchaseRequest.assignedTo._id) {
        title = 'Update PO and Send Back to CP Assigned?'
        message = `You are proceeding to update this PO. The PO will then be sent to (${this.purchaseRequest.assignedTo.name}) for re-submitting.`
      }
      this.$swal({
        title,
        html: message,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: 'Yes, Proceed',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.submitForm()
          }
        })
    },
    submitForm() {
      this.$refs.poCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('vendorName', this.vendorName)
          formData.append('url', window.location.origin)
          formData.append('salesPersonName', this.salesPersonName)
          formData.append('vendorAddress', this.vendorAddress)
          formData.append('companyContact', this.companyContact)
          formData.append('companyHP', this.companyHP)
          formData.append('companyFax', this.companyFax)
          formData.append('companyEmail', this.companyEmail)
          formData.append('paymentTerms', this.paymentTerms)
          formData.append('deliveryLeadTime', this.deliveryLeadTime)
          formData.append('deliverTo', this.deliverTo)
          formData.append('deliveryInfo', this.deliveryInfo)
          formData.append('address', this.address)
          formData.append('mainContactPerson', this.mainContactPerson)
          formData.append('mainContactPersonContact', this.mainContactPersonContact)
          formData.append('contactFax', this.contactFax)
          formData.append('contactEmail', this.contactEmail)
          formData.append('secondContactPerson', this.secondContactPerson)
          formData.append('backupContact', this.backupContact)
          formData.append('itemsString', this.itemsString)
          formData.append('requestID', this.$route.params.parent)
          formData.append('signatoryRequired', this.signatoryOption)
          formData.append('signatoryAssigns', JSON.stringify(this.signatoryAssigns))

          this.$http.patch(`purchase/purchase-order/${this.$route.params.id}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                if (this.saveType === 'preview') {
                  let message = `${this.purchaseRequest.caseID} has been updated, and will now be sent to the next assigned signatory (${this.resolveNextSignatoryName()}) for signing.`

                  if (this.user._id !== this.purchaseRequest.assignedTo._id) {
                    message = `${this.purchaseRequest.caseID} has been updated, and will now be sent to (${this.purchaseRequest.assignedTo.name}) for re-submitting.`
                  }
                  this.$swal({
                    title: 'Purchase Order Updated',
                    html: message,
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/success.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary mr-1',
                      cancelButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                    .then(result => {
                      if (result.value) {
                        this.$router.push({ name: 'purchasing-purchase-request-show', params: { id: this.$route.params.parent } })
                      }
                    })
                } else {
                  this.$swal({
                    title: 'Draft Saved',
                    html: 'You can continue editing this Purchase Order at a later time before sending it out for approval.',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary mr-1',
                      cancelButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                    .then(result => {
                      if (result.value) {
                        this.$router.push({ name: 'purchasing-purchase-request-show', params: { id: this.$route.params.id } })
                      }
                    })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'deliveryDate') {
                    this.salesPersonNameError = validationError.msg
                    this.salesPersonNameValidation = true
                  } else if (validationError.param === 'deliverTo') {
                    this.deliverToError = validationError.msg
                    this.deliverToValidation = true
                  } else if (validationError.param === 'deliveryInfo') {
                    this.deliveryInfoError = validationError.msg
                    this.deliveryInfoValidation = true
                  } else if (validationError.param === 'purpose') {
                    this.purposeError = validationError.msg
                    this.purposeValidation = true
                  } else if (validationError.param === 'address') {
                    this.addressError = validationError.msg
                    this.addressValidation = true
                  } else if (validationError.param === 'requestType') {
                    this.requestTypeError = validationError.msg
                    this.requestTypeValidation = true
                  } else if (validationError.param === 'certificates') {
                    this.certificatesError = validationError.msg
                    this.certificatesValidation = true
                  } else if (validationError.param === 'signatoryOption') {
                    this.signatoryOptionError = validationError.msg
                    this.signatoryOptionValidation = true
                  } else if (validationError.param === 'company') {
                    this.companyError = validationError.msg
                    this.companyValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style scoped>
  .removeText {
    color:#D91B35;
    cursor: pointer;
  }
  .addOptionText {
    color: #104D9D;
    cursor: pointer;
  }
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
  .inlineElement {
    display: flex;
  }
  .step-class {
    color: #104D9D;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }
  .dropzone-custom-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .dropzone-custom-title {
    margin-top: 0;
    color: #104D9D;
  }
  .subtitle {
    color: #333333;
  }
  .bookingCounter {
    border-radius: 50%;
    width: 5px;
    height: 5px;
    padding: 2px;

    background: #fff;
    border: 1px solid #666;
    color: #666;
    text-align: center;

    font-size: 10px;
  }

  .vs__dropdown-option--disabled {
    background: #ededed;
    color: #000000;
  }
</style>
